.detail__house-explenation {
    text-align: left;
    font-size: 26px;
    font-weight: 400;
    margin-top: 0;
    position: absolute;
    width: 100%;
    text-align: center;
    top: 230px;
    min-width: 320px;
    line-height: 35px;
}

.detail__house-category {
    color: rgba(53, 176, 128, 1);
    font-size: 35px;
    font-weight: 300;
    margin-bottom: 8px;
    position: absolute;
    width: 100%;
    text-align: center;
    top: 150px;
    min-width: 320px;
}

.detail__titles {
    text-align: center;
    width: 90%;
}
.react-photo-gallery--gallery {
    width: 80% !important;
    top: 300px !important;
    position: absolute !important;
    text-align: center !important;
    left: 50% !important;
    margin-left: -40% !important;
}

.detail__container {
    animation: fadeInWoningen 0.6s;
}

.detail__gallery {
    padding-top: 340px;
    position: relative;
    max-width: 100%;
    margin-bottom: 50px;
    min-width: 320px;
}

.detail__gallery > .ReactGridGallery {
    max-width: 90%;
    margin: 0 auto;
}

.footer_detail > div {
    /* top: 300px !important; */
    position: initial;
    bottom: 0;
}

@keyframes fadeInWoningen {
    0%   {transform: translateY(0px); opacity: 0}
    1%   {transform:translateY(50px); opacity: 0}
    100% {transform:translateY(0px); opacity: 1}
}

.detail__gallery .ReactGridGallery_tile {
    width: 80%;
    background-color: transparent !important;
    float: none !important;
    margin: 0 auto !important;
    z-index: 998;
}

.detail__gallery .ReactGridGallery_tile-viewport {
    height: auto !important;
}

.detail__gallery .ReactGridGallery_tile-viewport > img {
    width: 100%;
    height: auto !important;
}

.detail__gallery .ReactGridGallery_tile-icon-bar {
    display: none;
}

.detail__gallery--1child .ReactGridGallery_tile {
    width: 80%;
    margin: 0 auto !important;
}

    .footer_detail > .footers {
        position: relative;
    }


@media only screen and (min-width: 700px) {
    .detail__gallery .ReactGridGallery_tile {
        width: 75%;
    }
  }

  @media only screen and (min-width: 800px) {
    .detail__gallery .ReactGridGallery_tile {
        width: 48%;
        margin: 5px !important;
        float: left !important;   
    }

    .detail__gallery--3childs .ReactGridGallery_tile:nth-child(4)  {
        width: 96% !important;
        margin-left: 10px !important;
    }

    .detail__gallery--1child .ReactGridGallery_tile {
        width: 70%;
        margin: 0 auto !important;
        float: none !important;
        max-width: 500px
    }
  }
/* 
  @media only screen and (min-width: 1400px) {
    .detail__gallery .ReactGridGallery_tile {
        width: 33%;
        margin: 5px !important;
        float: left !important;
        max-width: 772px;
    }
} */

 /* @media only screen and (min-width: 1400px) {
    .detail__gallery .ReactGridGallery_tile {
        width: 33%;
        margin: 5px !important;
        float: left !important;
        max-width: 772px;
    }
} */

  @media only screen and (min-width: 1400px) {
    .detail__gallery--3childs .ReactGridGallery_tile {
        width: 32.5% !important;
        margin: 5px !important;
        float: left !important;
        max-width: 800px;
    }

    .detail__gallery--3childs .ReactGridGallery_tile:nth-child(4)  {
        width: 32.5% !important;
        margin: 5px !important;
    }
  }
  @media only screen and (max-height: 1000px)  {

  }