/* General Header   */
  
a {
  color: white;
  text-decoration: none;
  z-index: 998;
  position: relative;
}

.header__container--li-black {
  color: white;
}

.header__container--li-white {
  color: white;
}

.header__container--li-black:hover {
  color: white;
  transition: 0.3s;
}

.header__container--li-white:hover {
  color: black;
  transition: 0.3s;
} 

header {
  width: 100%;
  z-index: 997;
  min-width: 320px;
  height: 100%;
  transition: 0.3s;
}

.posFixedHeader {
  position: fixed;
}

.hideBlack {
  background-color: transparent;
  z-index: 997;
  position: absolute;
}

.showBlack {
  background-color: rgba(0, 0, 0, 1);
  height: 100%;
  z-index: 999;
}

.header__container {
  width: 80%;
  margin: 0 auto;
}

.hamburger-react {
  margin-top: 65px;
  padding-bottom: 20px;
  position: absolute !important;
  float: right;
  right: 30px;
}

/* Not scrolled   */

.header__container--logo {
  max-width: 180px;
  margin-top: 65px;
  float: left;
  
}

.header__container--ul {
  background-color: transparent;
  list-style-type: none;
  float: right;
  margin-top: 25px;
  padding: 0;
  display: none;
  top: 170px;
  position: absolute;
  width: 100%;
  margin: 0 auto;
  left: 0;
  right: 0;
}

.header__container--ul-show {
  display: block;
}

.line {
  color: rgba(53, 176, 128, 1) !important;
  font-size: 10px !important;
  text-decoration: none;
  padding-bottom: 55px !important;
}

.line > p {
  padding-top: 30px;
}

.line p > a {
  color: rgba(53, 176, 128, 1) !important;
}

.line:before {
  content: "";
  position: absolute;
  top: 0.5rem;
  background-color: rgba(53, 176, 128, 1);
  height: 3px;
  width: 26px;
  margin-top: 10px;
  margin-left: -13px;
  left: 50%;
}

.lineContact > p {
  margin-bottom: 0px;
}

.lineContact:before {
  content: "";
  position: absolute;
  top: 0.5rem;
  background-color: rgba(53, 176, 128, 1);
  height: 3px;
  width: 26px;
  margin-top: 10px;
  margin-left: -13px;
  left: 50%;
}

.header__container--ul > li {
  display: block;
  color: white;
  font-size: 25px;
  font-weight: 400;
  padding:10px 30px 10px 30px;
  animation: fadeIn 0.3s ease-in both;
}

.header__container--active-li {
  color: rgba(53, 176, 128, 1);
}

.header__container--li:hover {
  color: rgba(53, 176, 128, 1);
  transition: 0.3s;
}

.header__container--li:active {
  color: rgba(53, 176, 128, 1);
}

.header__container--ul > li:nth-last-child(2) {
  padding-right: 30px;
}

#nav-icon3{
  width: 60px;
  height: 45px;
  position: relative;
  margin: 50px auto;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
}

#nav-icon3 span {
  display: block;
  position: absolute;
  height: 9px;
  width: 100%;
  background: #d3531a;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

/* Icon 3 */

.header__container--hamburger span:nth-child(1) {
  top: 0px;
}

.header__container--hamburger span:nth-child(2),.header__container--hamburger span:nth-child(3) {
  top: 18px;
}

.header__container--hamburger span:nth-child(4) {
  top: 36px;
}

.header__container--hamburger.open span:nth-child(1) {
  top: 18px;
  width: 0%;
  left: 50%;
}

.header__container--hamburger.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.header__container--hamburger.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.header__container--hamburger.open span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate3d(0, -30%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}


@media only screen and (min-width: 420px) {
  .header__container--logo {
    max-width: 180px;
    float: left;
  }
}

@media only screen and (min-width: 500px) {
  .hamburger-react {
    margin-right: 20px;
  }
  
}

@media only screen and (min-width: 330px) and (min-height: 580px)  {
  .header__container--ul > li {
    font-size: 30px;
  }
}

@media only screen and (min-width: 400px) and (min-height: 600px) {
  .header__container--ul {
      margin-top: 50px;
  }
}

@media only screen and (max-height: 550px) {
  .header__container--ul {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden; 
  }
}

@media only screen and (min-height: 541px) and (min-width: 700px) {
  .header__container--ul {
    height: 73vh;
  }
}

@media only screen and (max-height: 550px) and (min-width: 700px) {
  .header__container--ul {
    height: 70vh;
  }
}

@media only screen and (min-width: 1080px) {
  .header__container--logo {
    max-width: 180px;
    margin-top: 65px;
  }

  .header {
    background-color: transparent;
  }

  .hamburger-react {
    display: none;
  }

  .header__container--ul {
    display: inline-block;
    top: 15px;
    position: unset;
    width: auto;
  }

  .header__container--ul > li:-last-child(2) {
    padding-right: 0px;
  }
  
  .header__container--ul > li {
    display: inline-block;
    animation: none;
  }
      
  .line {
    display: none !important;
  }

  .lineContact:before {
    display: none !important;
  }
  
  .header__container--ul > li {
    font-size: 20px;
    padding:10px 20px 10px 20px;
  }

  .header__container--li-black {
    color: black;
  }
  
  .header__container--li-white {
    color: white;
  }
}

@media only screen and (min-width: 1240px) {
  .header__container--ul > li {
    font-size: 25px;
  }
}

@media only screen and (min-width: 1350px) {
  .header__container--ul > li {
    font-size: 28px;
  }
}

@media only screen and (min-width: 1450px) {
  .header__container--ul > li {
    font-size: 28px;
  }

  .header__container--logo {
    max-width: 220px;
  }
}

@media only screen and (min-width: 1500px) {
  .header__container--ul > li {
    padding: 10px 30px 10px 30px;
  }
}