.visualisaties:before {
    background-image: url(../Images/1d.webp);
    min-height: 250px;
    width: 100%;
    background-size: cover!important;
    background-position: 50% 50%!important;
    height: 80vh;
    background-attachment: fixed;
    animation: fadeCoverWoningen 0.9s;
    z-index: 1;
    opacity: 0.4;
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
}

.visualisaties__title {
    font-size: 22px;
    position: absolute;
    width: 100%;
    margin: 0 auto;
    top: 300px;
    z-index: 998;
    left: 0;
    font-family: 'codeb';
    letter-spacing: 10px;
}

.visualisaties__under-title {
    font-size: 15px;
    line-height: 20px;
    position: absolute;
    width: 100%;
    margin: 0 auto;
    top: 400px;
    color: dimgray;
    z-index: 998;
    left: 0;
    font-family: 'codeb';
    letter-spacing: 5px;
}

.visualisaties__under-title > p {
    padding-left: 50px;
    padding-right: 50px;
}

.visualisaties__cover-image--container {
    width: 100%;
    height: 80vh;
    min-height: 250px;
} 

.visualisaties__container {
    margin: 0 auto;
    max-width: 90%;
    min-height: 400px;
    animation: fadeInVisualisaties 0.6s;
    margin-top: 25px;
    margin-bottom: 25px;
    z-index: 999;
}

.visualisaties__container .ReactGridGallery_tile {
    width: 80%;
    background-color: transparent !important;
    float: none !important;
    margin: 0 auto !important;
    z-index: 998;
}

.visualisaties__container .ReactGridGallery_tile-viewport {
    height: auto !important;
}

.visualisaties__container .ReactGridGallery_tile-viewport > img {
    width: 100%;
    height: auto !important;
}

.visualisaties__container .ReactGridGallery_tile-icon-bar {
    display: none;
}

@keyframes fadeCoverVisualisaties {
    0%   {opacity: 0.8}
    100% {opacity: 1}
}

@keyframes fadeInVisualisaties {
    0%   {transform: translateY(0px); opacity: 0}
    1%   {transform:translateY(50px); opacity: 0}
    100% {transform:translateY(0px); opacity: 1}
}

@media only screen and (min-width: 370px) {
    .visualisaties__title {
        font-size: 30px !important;    
    }

    .visualisaties__under-title {
        font-size: 18px;
    }
  }


@media only screen and (min-width: 485px) {
    .visualisaties__container .visualisaties__title {
        font-size: 50px !important;    
    }
  }

@media only screen and (min-width: 700px) {
    .visualisaties__container .ReactGridGallery_tile {
        width: 48%;
        margin: 5px !important;
        float: left !important;
    }
  }

  @media only screen and (min-width: 1400px) {
    .visualisaties__container .ReactGridGallery_tile {
        width: 32.5%;
        margin: 5px !important;
        float: left !important;
        max-width: 800px;
    }
  }


  @media only screen and (max-height: 700px) {
    .visualisaties__title {
        top: 175px;
    }
    
    .visualisaties__under-title {
        top: 275px;
    }

  }

  @media only screen and (max-height: 420px) {
    .visualisaties__title {
        top: 110px;
    }
    
    .visualisaties__under-title {
        top: 180px;
    }
        
    .visualisaties__title {
        font-size: 35px !important;
    }
  }

