body {
  margin: 0;
  font-family: 'nexal', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: RGBA(249, 249, 249, 1);
  letter-spacing: 0.5px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'codeb';
  src: url('./Fonts/CodeBold.otf') format('opentype');
  font-display: swap;
}  

@font-face {
  font-family: 'nexal';
  src: url('./Fonts/NexaLight.otf') format('opentype');
  font-display: swap;
}  

html {
  scroll-behavior: smooth;
}