  /* Contact general */

  .contact {
    height: 93.5vh;
  } 

  .contact__info-box--hide {
    height: 118vh;
  }


  /* Map  */

  .contact__map {
    width: 100% !important;
    height: 100% !important;
    opacity: 0.2;
    z-index: 99;
    position: fixed !important;
    animation: fadeInMap 0.6s;
  }

  .contact__map--opacity-full {
    opacity: 1;
    position: fixed !important;
  }

  .contact__color-field {
    background-color: rgb(53, 176, 128);
    height: 48%;
    width: 90%;
    top: 220px;
    margin: 0 auto;
    z-index: 998;
    opacity: 1;
    position: relative;
    box-shadow: 0 0 50px -10px grey;
    max-width: 90%;
    min-width: 310px;
    max-height: 440px;
    max-width: 1110px;
    animation: fadeInContact 0.6s;
  }

  .contact__snackbar-info-box--reopen {
    max-width: 40px;
  }

  .contact__snackbar-info-box--text {
    color: rgb(53, 176, 128) !important;
  }

  .contact__color-field-hideInfoBox {
    display: none;
  }

  .contact__color-field-green {
    background-color: rgb(53, 176, 128);
    float: right;
    width: 100%;
    height: 91%;
    color: white;
    text-align: left;
    font-size: 16px;
    min-height: 380px;
    line-height: 20px;
  }

  .contact__color-field-green > p,  .contact__color-field-green > h4 {
    padding-left: 0;
  }

  .contact__color-field-green > h4 {
    font-weight: 500;
    font-size: 30px;
    margin-top: 50px;
    text-align: left;
    padding-left: 25px;
  }

  .contact__color-field-green--text > p {
    margin-bottom: 35px;
    margin-top: 0;
  }

  .contact__color-field-green--text > p:first-child {
    margin-bottom: 35px;
    margin-top: -5px;
  }

  .contact__color-field-green--text > p:nth-child(2) {
    margin-bottom: 35px;
    margin-top: -3px;
  }
  .contact__color-field-green--text > p:nth-child(3) {
    margin-bottom: 38px;
    padding-top: 8px;
  }
  .contact__color-field-green--text > p:nth-child(4) {
    margin-bottom: 35px;
    padding-top: 8px;
  }
  .contact__color-field-white {
    background-color: rgb(255, 255, 255);
    float: left;
    width: 100%;
    height: 100%;
    min-height: 420px;
  }

  .contact__color-field-green--close-button {
    right: 0;
    position: absolute !important;
    margin-right: 10px !important;
    margin-top: 10px !important;
  }

  .contact__contact-form--div {
    max-width: 100% !important;
    width: 100%;
  }

  .contact__contact-form--textfield-name {
    width: 90% !important;
    margin-top: 30px !important;
  }

  .contact__contact-form--textfield-phone {
      width: 90% !important;
      margin-top: 30px !important;
  }

  .contact__contact-form--textfield-email, .contact__contact-form--textfield-message {
      width: 90% !important;
      margin-top: 30px !important;
  }

  .contact__contact-form--textfield-error {
    margin-top: 10px !important;
  }


  .contact__contact-form--submit-btn {
    margin-right: 5% !important;
    float: right;
    margin-top: 30px !important;
    background-color: rgb(53, 176, 128) !important;
  }

  .contact__color-field-green--home-icon, .contact__color-field-green--call-icon, .contact__color-field-green--mail-icon, .contact__color-field-green--business-icon {
    padding-right: 10px;
    margin-bottom: 40px;
    padding-left: 25px;
  }

  .contact__color-field-green--signs {
    float: left;
    display: inline-grid !important;
  }


  .MuiSnackbarContent-action {
    margin-right: 0 !important;
    padding-left: 0 !important;
  }

  .MuiSnackbar-anchorOriginBottomCenter {
    margin: 0 auto !important;
    bottom: 20px !important;
  }

  @keyframes fadeInContact {
    0%   {transform: translateY(0px); opacity: 0}
    1%   {transform:translateY(50px); opacity: 0}
    100% {transform:translateY(0px); opacity: 1}
  }

  @keyframes fadeCoverMap {
    0%   {opacity: 0.9}
    100% {opacity: 1}
  }

  @media only screen and (max-height: 1170px) and (max-width: 970px) {
    .footer_contact {
      top: 1100px;
    }
  }

  @media only screen and (max-height: 820px) and (min-width: 970px) {
    .footer_contact {
      top: 750px !important;
    }
  }


  @media only screen and (min-width: 300px) {
    .footer__copyright--position-ab {
      position: fixed;
      bottom: 0;
    }
  }  

  @media only screen and (max-height: 800px) and (min-width: 970px) {
    .footer__copyright--position-ab {
        top: 740px;
        position: absolute;
    }
  }  

  @media only screen and (max-height: 1170px) and (max-width: 970px) {
    .footer__copyright--position-ab {
        top: 1096px;
        position: absolute;
    }
  }  

  @media only screen and (min-height: 820px) and (min-width: 970px) {
    .footer__copyright--position-ab {
      /* position: fixed;
      bottom: 0; */
    }
  }  

  @media only screen and (min-height: 1170px) and (max-width: 970px){
    .footer_contact {
      position: fixed;
      bottom: 0;
    }
  }
  
  @media only screen and (min-width: 970px) {
    .contact__color-field-green {
      width: 36%;
      min-height: 420px;
    }

    .contact__color-field-white {
      width: 64%;
    }
    .contact__contact-form--submit-btn {
      margin-top: 30px !important;
    }

    .contact__contact-form--textfield-phone {
      width: 42% !important;
      margin-right: 5% !important;
    }

    .contact__contact-form--textfield-name {
      width: 42% !important;
      margin-right: 5% !important;
      margin-left: 5% !important;
    }

    .contact__color-field-green > h4 {
      text-align: left;
      font-size: 21px;
    }

    .contact__color-field-green > p,  .contact__color-field-green > h4 {
      padding-left: 25px;
    }

    .contact__color-field-green {
      font-size: 16px;
      height: 100%;
    }

    .contact__color-field-green--text > p:first-child {
      margin-bottom: 35px;
      margin-top: -4px;
    }

    .contact__color-field-green--text > p:nth-child(3) {
      padding-top: 8px;
    }

    .contact__info-box--hide {
      height: 93.5vh;
    }

    .MuiSnackbarContent-root {
      min-width: 40px !important;
    }

    .contact__snackbar-info-box--reopen {
      max-width: none;
    }

    .contact__color-field {
      top: 290px;
    }


  }

  @media only screen and (min-width: 1400px) {
    .contact__color-field {
      width: 60%;
      height: 40%;
    }
  }