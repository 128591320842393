
/* General footer */

.footer {
    width: 100%;
    z-index: 998;
    min-width: 320px !important;
}

.footer__copyright--position-ab {
    /* position: absolute; */
}

.footer__copyright, .footer__info-architect {
    width: 100%;
    float: left;
}

.footer__contact--show {
    display: block;
}

.footer__contact--hide {
    display: none;
}

.footer__copyright--show {
    display: block;
}

.footer__copyright--hide {
    display: none;
}

.footer__info--show {
    display: block;
}

.footer__info--hide {
    display: none;
}

/* Footer Copyright */

.footer__copyright {
    background-color: RGBA(37, 43, 55, 1);
    color: white;
    height: 70px;
    font-size: 15px;
}

.footer__copyright > p {
    margin-top: 0;
    padding-top: 23px;
}


/* Footer info */

.footer__info-architect {
    color: white;
    height: 300px;
    font-size: 15px;
    background-color: RGBA(41, 46, 58, 1);
}

.footer__info-architect--logo {
    max-width: 200px;
    margin-top: 40px;
}

.footer__info-architect--address {
    margin-right: 10px;
}

.footer__info-architect--info {
    margin-left: 10px;
}

.footer__info-architect--info, .footer__info-architect--address {
    margin-top: 35px;
    line-height: 18px;
}

/* Footer contact form */

.footer__contact-form {
    /* margin-top: 50vh; */
    height: 350px;
    font-size: 15px;
    background-color: RGBA(240, 240, 240, 1);
}

.footer__contact-form--div {
    max-width: 80%;
    margin: 0 auto;
}

.footer__contact-form--textfield-name {
    width: 100%;
}

.footer__contact-form--textfield-phone {
    width: 100%;
}

.footer__contact-form--textfield-email, .footer__contact-form--textfield-message {
    width: 100%;
}

.footer__contact-form--submit-btn {
    float: right;
    margin-top: 30px !important;
    background-color: RGBA(37, 43, 55, 1) !important;
    z-index: 998;
}

.footer__contact-form--submit-btn:hover {
    background-color: rgba(108, 122, 137, 1) !important;
}

.footer__contact-form--submit-btn > span::after {
    border: none !important;
}

.footer__contact-form--textfield-email, .footer__contact-form--textfield-message, .footer__contact-form--textfield-phone, .footer__contact-form--textfield-name {
    margin-top: 15px !important;
    z-index: 998;
}

.footer__contact-form--textfield-error > div:before {
    border-bottom-color: maroon !important;
}

.footer__contact-form--textfield-error > label {
    color: maroon !important;
}

.MuiFormHelperText-root {
    color: maroon !important;
    font-size: 13px !important;
}

@media only screen and (min-width: 650px) {
    .footer__contact-form--textfield-name {
        width: 45%;
        margin-right: 5% !important;
    }
    
    .footer__contact-form--textfield-phone {
        width: 45%;
        margin-left: 5% !important;
    }
}

@media only screen and (min-width: 1240px) {
    .footer__contact-form--div {
        max-width: 50%;
        margin: 0 auto;
    }
}

@media only screen and (min-width: 500px) {
    .footer__info-architect--info, .footer__info-architect--address {
        display: inline-block;
        margin-top: 50px;
    }

    .footer__info-architect--address {
        text-align: right;
    }
    
    .footer__info-architect--info {
        text-align: left;
    }

    .footer__info-architect {
        height: 250px;
    }   
}
