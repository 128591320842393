/* Photogrid */

.photo-grid {
	max-width: 80%;
	margin: 0 auto;
	position: relative;
    margin-top: 50px;
    margin-bottom: 30px;
    min-height: 200px;
}

.photo-grid__paper-box {
    box-shadow: none !important; 
    background-color: transparent !important;    
}

.addAnimation {
    animation: fadeInHome 0.6s;
}

.hidePaperbox > div {
    display: none;
}

/* Photogrid settings */

.photo-grid__paper-box--image {
	width: 100%;
	height: auto;
	overflow: hidden;
    margin: 0 auto;
    z-index: 990;
}

.photo-grid__paper-box--house-category {
    text-align: left;
    color: rgba(53, 176, 128, 1);
    font-size: 18px;
    font-weight: 300;
    margin-bottom: 8px;
}

.photo-grid__paper-box--house-explenation {
    text-align: left;
    font-size: 21px;
    font-weight: 400;
    margin-top: 0;
}

.photo-grid__paper-box--xs {
	width: 100%;
    height: auto;
    transition: transform 0.3s;
    transition: 0.3s;
}

.photo-grid__paper-box--xs:hover { 
    transform: scale(1.005);
    opacity: 0.6;
}

.MuiGrid-spacing-xs-3 > .MuiGrid-item {
    min-width: 280px !important;
}

@keyframes fadeInHome {
    0%   {transform: translateY(0px); opacity: 0}
    1%   {transform:translateY(50px); opacity: 0}
    100% {transform:translateY(0px); opacity: 1}
}

@media only screen and (min-width: 400px) {
    .photo-grid__paper-box--house-explenation {
        font-size: 26px;
    }
}

@media only screen and (min-width: 1025px) {
    .photo-grid__paper-box--house-explenation {
        font-size: 21px;
    }
}

@media only screen and (min-width: 1230px) {
    .photo-grid__paper-box--house-explenation {
        font-size: 26px;
    }
}